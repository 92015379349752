import {
    Checkbox,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuTrigger,
    cn,
} from '@i2e/components';
import { ExpandMoreIcon } from '@in2event/icons';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../form-fields';

export interface DropdownCheckboxFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string;
    options: { id: string; label: string }[];
    placeholder?: string;
    getSelectedLabel?: (value: string[]) => string;
    description?: string;
    t?: (key: string) => string;
}

const DropdownCheckboxField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    options,
    placeholder,
    getSelectedLabel,
    description,
    t,
    ...props
}: DropdownCheckboxFieldProps<TFieldValues, TName>) => {
    const getDisplayText = (value: string[] | undefined, placeholder?: string) => {
        if (value && value.length === 0) {
            return <span className="text-neutral-50">{placeholder ?? 'Select options'}</span>;
        }

        if (getSelectedLabel && value) {
            return getSelectedLabel(value);
        }

        return options
            .filter((option) => value?.includes(option.id))
            .map((option) => option.label)
            .join(', ');
    };

    return (
        <FormField
            {...props}
            render={({ field }) => (
                <FormItem>
                    <div className="mb-1">
                        <FormLabel>{label}</FormLabel>
                    </div>

                    <DropdownMenuRoot>
                        <FormControl>
                            <DropdownMenuTrigger
                                className={cn(
                                    'flex h-10 w-full items-center justify-between rounded-md border-none bg-neutral-2100 p-2 text-sm font-medium text-neutral-250 outline-none',
                                    'disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-4 disabled:text-neutral-1100',
                                )}
                            >
                                <span className="text-sm font-medium">
                                    {getDisplayText(field.value, placeholder)}
                                </span>
                                <ExpandMoreIcon className="size-5" />
                            </DropdownMenuTrigger>
                        </FormControl>
                        <DropdownMenuContent align="start" className="w-[240px]">
                            <div className="max-h-[250px] overflow-y-auto">
                                {options.map((option) => (
                                    <DropdownMenuItem
                                        key={option.id}
                                        onSelect={(e) => {
                                            e.preventDefault();
                                            const newValue = field.value.includes(option.id)
                                                ? field.value.filter(
                                                      (value: string) => value !== option.id,
                                                  )
                                                : [...field.value, option.id];
                                            field.onChange(newValue);
                                        }}
                                    >
                                        <Checkbox
                                            checked={field.value.includes(option.id)}
                                            readOnly
                                        />
                                        <span className="ml-2">{option.label}</span>
                                    </DropdownMenuItem>
                                ))}
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenuRoot>

                    <FormDescription>{description}</FormDescription>
                    <FormMessage t={t} />
                </FormItem>
            )}
        />
    );
};

export default DropdownCheckboxField;
