'use client';

import { cn } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import type {
    GroupBase,
    InputActionMeta,
    MenuPlacement,
    MenuPosition,
    OptionsOrGroups,
} from 'react-select';
import AsyncCreatable from 'react-select/async-creatable';

import { FormDescription, FormField, FormItem, FormLabel, FormMessage, useFormField } from '..';
import { MultiSelectValues } from '../async-select-field';
import { ClearIndicator, DropdownIndicator } from '../react-select-components';

export const createOption = (inputValue: string) => ({
    label: inputValue,
    value: inputValue.toLowerCase().replace(/\W/g, ''),
});

// Create type guard for the options
export const isOptions = (
    options: AsyncCreatableOptionsType,
): options is OptionsOrGroups<unknown, GroupBase<unknown>> => {
    return Array.isArray(options);
};

// Create type guard for newValue in onChange
export const isNewValue = (newValue: unknown): newValue is { value: string; label: string } => {
    return (
        typeof newValue === 'object' &&
        newValue !== null &&
        'value' in newValue &&
        'label' in newValue
    );
};

export type AsyncCreatableOptionsType = OptionsOrGroups<unknown, GroupBase<unknown>>;

interface AsyncCreatableFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label?: string | null;
    description?: string | null;
    placeholder?: string;
    className?: string | undefined;
    isDisabled?: boolean;
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
    options?: AsyncCreatableOptionsType | undefined;
    defaultOptions?: AsyncCreatableOptionsType | boolean | undefined;
    cacheOptions?: boolean;
    loadOptions?: (
        inputValue: string,
        callback: (options: AsyncCreatableOptionsType) => void,
    ) => void;
    onCreateOption?: (inputValue: string, callback: (data: unknown) => void) => void;
    isMulti?: boolean;
    formatOptionLabel?: (option: unknown) => React.ReactNode;
    formatCreateLabel?: ((inputValue: string) => string) | undefined;
    menuPortalTarget?: HTMLElement | null | undefined;
    menuPlacement?: MenuPlacement | undefined;
    maxMenuHeight?: number | undefined;
    menuPosition?: MenuPosition | undefined;
    allowCreateWhileLoading?: boolean;
    isLoading?: boolean;
}

const AsyncCreatableField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    placeholder,
    className,
    isDisabled,
    onInputChange,
    options,
    loadOptions,
    defaultOptions,
    cacheOptions,
    onCreateOption,
    isMulti = false,
    formatOptionLabel,
    formatCreateLabel,
    menuPortalTarget,
    maxMenuHeight = 144,
    menuPlacement = 'auto',
    menuPosition,
    allowCreateWhileLoading = false,
    isLoading,
    ...props
}: AsyncCreatableFieldProps<TFieldValues, TName>) => {
    const { error } = useFormField();

    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => {
                const handleOnRemove = (targetValue: string) => {
                    const newValue = value.filter(
                        (option: { value: string }) => option.value !== targetValue,
                    );

                    field.onChange(newValue);
                };

                return (
                    <FormItem className={className}>
                        {label ? <FormLabel>{label}</FormLabel> : null}

                        {isMulti ? (
                            <MultiSelectValues value={value} onRemove={handleOnRemove} />
                        ) : null}

                        <AsyncCreatable
                            {...field}
                            onCreateOption={(inputValue) => {
                                if (onCreateOption) {
                                    onCreateOption(inputValue, (data) => {
                                        field.onChange(isMulti ? [...value, data] : data);
                                    });
                                } else {
                                    field.onChange(
                                        isMulti
                                            ? [...value, createOption(inputValue)]
                                            : createOption(inputValue),
                                    );
                                }
                            }}
                            value={value as AsyncCreatableOptionsType}
                            onChange={(newValue, actionMeta) => {
                                if (actionMeta.action === 'select-option') {
                                    field.onChange(newValue);
                                }

                                if (actionMeta.action === 'clear' && !isMulti) {
                                    field.onChange(newValue);
                                }
                            }}
                            inputId={field.name}
                            createOptionPosition="first"
                            instanceId={`react-select-creatable-${props.name}`}
                            options={options}
                            defaultOptions={defaultOptions}
                            loadOptions={loadOptions}
                            cacheOptions={cacheOptions}
                            onInputChange={onInputChange}
                            allowCreateWhileLoading={allowCreateWhileLoading}
                            isLoading={isLoading}
                            placeholder={placeholder}
                            formatOptionLabel={formatOptionLabel}
                            formatCreateLabel={formatCreateLabel}
                            menuPortalTarget={menuPortalTarget}
                            maxMenuHeight={maxMenuHeight}
                            menuPlacement={menuPlacement}
                            menuPosition={menuPosition}
                            isClearable={!isMulti}
                            escapeClearsValue={!isMulti}
                            isMulti={isMulti}
                            controlShouldRenderValue={!isMulti}
                            isDisabled={isDisabled}
                            components={{
                                DropdownIndicator,
                                ClearIndicator,
                                IndicatorSeparator: null,
                            }}
                            styles={{
                                option: (providedStyles, { data }) => ({
                                    ...providedStyles, // To keep the default style
                                    ...((data as { __isNew__: boolean }).__isNew__ && {
                                        color: '#0057FF',
                                        fontWeight: 600,
                                    }),
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                                control: (base, { isFocused }) => ({
                                    ...base,
                                    backgroundColor: '#F6F6F6',
                                    borderColor: isFocused
                                        ? 'rgba(0, 87, 255, 1)'
                                        : 'rgba(23, 23, 23, 0.1)',
                                    borderWidth: 2,
                                    outline: 'none',
                                    boxShadow: 'none',
                                    ...(error && {
                                        borderColor: 'rgba(221, 59, 7, 1)',
                                    }),
                                }),
                            }}
                            classNames={{
                                control: ({ isDisabled }) =>
                                    cn(
                                        isDisabled
                                            ? 'bg-neutral-2100 text-neutral-1100 cursor-not-allowed border-none'
                                            : 'cursor-pointer',
                                        'text-neutral-250 text-sm font-medium',
                                    ),
                                option: ({ isFocused, isSelected }) =>
                                    cn(
                                        'text-neutral-250 text-sm font-medium hover:cursor-pointer',
                                        {
                                            'bg-primary-100 text-primary-900':
                                                isFocused || isSelected,
                                        },
                                    ),
                                valueContainer: () => 'text-sm',
                                noOptionsMessage: () => 'text-sm',
                                loadingMessage: () => 'text-sm',
                                menu: () => 'text-sm',
                                menuList: () => 'text-sm',
                                placeholder: () => 'text-sm font-medium text-neutral-50',
                            }}
                        />

                        <FormDescription>{description}</FormDescription>
                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};

export default AsyncCreatableField;
