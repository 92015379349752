import {
    SelectContent,
    SelectIcon,
    SelectItem,
    SelectItemText,
    SelectRoot,
    SelectTrigger,
    SelectValue,
    cn,
} from '@i2e/components';
import { ExpandMoreIcon } from '@in2event/icons';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';

export interface DropdownFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description?: string | null;
    options?: Record<string, string>[];
    placeholder?: string | null;
    t?: (key: string) => string;
}

const DropdownField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    options,
    placeholder,
    t,
    ...props
}: DropdownFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field }) => {
                const selectedValue = options?.find(
                    (option) => option.key === field.value?.toString(),
                )?.value;

                return (
                    <FormItem>
                        <FormLabel>{label}</FormLabel>
                        <SelectRoot value={field.value} onValueChange={field.onChange}>
                            <FormControl>
                                <SelectTrigger className="w-full justify-between">
                                    <SelectValue
                                        placeholder={placeholder ?? 'Select an option'}
                                        aria-label={field.value}
                                    >
                                        {selectedValue}
                                    </SelectValue>
                                    <SelectIcon asChild>
                                        <ExpandMoreIcon className="size-5" />
                                    </SelectIcon>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent
                                position="popper"
                                className="max-h-[var(--radix-select-content-available-height)] max-w-[var(--radix-select-content-available-width)]"
                            >
                                {options?.map(({ key, value }) => (
                                    <SelectItem
                                        key={key}
                                        value={key || ''}
                                        className={cn(
                                            '[data-state=checked]:bg-primary-200 h-14 font-semibold hover:cursor-pointer hover:text-primary-900 md:h-8',
                                        )}
                                    >
                                        <SelectItemText>{value}</SelectItemText>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </SelectRoot>
                        <FormDescription>{description}</FormDescription>
                        <FormMessage t={t} />
                    </FormItem>
                );
            }}
        />
    );
};

export default DropdownField;
