import { Label, Text } from '@i2e/components';
import { ReactNode } from 'react';

import {
    CheckboxField,
    CheckboxesField,
    DatePickerField,
    DropdownField,
    InputField,
    RadioButtonsField,
    TextareaField,
    UploaderField,
} from '..';
import { FieldSchemaType, FormFieldType } from '../schemas/form-field';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFieldValueBaseOnType: any = (type: FormFieldType) => {
    switch (type) {
        case 'checkbox':
            return false;
        case 'checkboxes':
            return [];
        default:
            return '';
    }
};

interface FormSwitchProps {
    fields: FieldSchemaType[];
    fieldIdentifier: string;
    api?: Record<string, string>;
    messages?: Record<string, Record<string, ReactNode>>;
}

export const FormSwitch = ({ fields, fieldIdentifier, api, messages }: FormSwitchProps) => {
    return (
        <>
            {fields.map((item, index) => {
                if (!item.type) {
                    return (
                        <InputField
                            {...item}
                            key={item.key}
                            name={`${fieldIdentifier}.${index}.value`}
                            rules={{
                                required: item.validation?.includes('required'),
                            }}
                        />
                    );
                }

                // Add a switch case to load the correct component based on the type of field
                switch (item.type) {
                    case 'avatar':
                        return api ? (
                            <UploaderField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                uploadUrl={api.uploader || ''}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                                message={messages?.uploader}
                            />
                        ) : null;
                    case 'description':
                        return (
                            <div className="flex flex-col space-y-2" key={item.key}>
                                <Label>{item.label}</Label>
                                <Text>{item.description}</Text>
                            </div>
                        );
                    case 'input': // 'input' is the same as 'text' (Legacy support for 'input' type)
                        return (
                            <InputField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'text':
                        return (
                            <InputField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'textarea':
                        return (
                            <TextareaField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'checkbox':
                        return (
                            <CheckboxField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'radiobuttons':
                        return (
                            <RadioButtonsField
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'datepicker':
                        return (
                            <DatePickerField
                                {...item}
                                key={item.key}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'checkboxes':
                        return (
                            <CheckboxesField
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    case 'dropdown':
                        return (
                            <DropdownField
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`${fieldIdentifier}.${index}.value`}
                                rules={{
                                    required: item.validation?.includes('required'),
                                }}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};
