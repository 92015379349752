/* eslint-disable @typescript-eslint/no-explicit-any -- needed */
import { format, isAfter, isBefore, isEqual, isValid, parse } from 'date-fns';
import { TFunction } from 'i18next';
import * as z from 'zod';

const options = z.array(
    z.object({
        key: z.union([z.string(), z.number()]).transform((val) => {
            if (typeof val === 'number') {
                return val.toString();
            }
            return val;
        }),
        value: z.string(),
    }),
);

const type = z.enum([
    'description',
    'text',
    'textarea',
    'checkbox',
    'radiobuttons',
    'datepicker',
    'checkboxes',
    'dropdown',
    'countries',
]);

const fieldSchema = z.object({
    id: z.number(),
    type,
    key: z.string(),
    label: z.string().nullable(),
    description: z.string().nullable(),
    value: z.unknown().optional(),
    validation: z.array(z.string()).nullable(),
    options: options.optional(),
    placeholder: z.string().nullable(),
});
type FieldSchemaType = z.infer<typeof fieldSchema>;

interface ValidationContext {
    fields?: FieldSchemaType[];
    field: FieldSchemaType;
    index: number;
    ctx: z.RefinementCtx;
    t?: TFunction;
}

const validateRequired = ({ field, index, ctx, t }: ValidationContext) => {
    // Text and textarea
    if (
        field.validation?.includes('required') &&
        typeof field.value === 'string' &&
        !field.value.trim()
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
            path: ['fields', index, 'value'],
        });
    }
    // Multiple checkboxes
    if (
        field.validation?.includes('required') &&
        field.type === 'checkboxes' &&
        Array.isArray(field.value) &&
        field.value.length === 0
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
                t?.('form_field_required_one_option' as any) ??
                'Minimaal één optie moet geselecteerd zijn',
            path: ['fields', index, 'value'],
        });
    }
    // Single checkbox
    if (field.validation?.includes('required') && field.type === 'checkbox' && !field.value) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
            path: ['fields', index, 'value'],
        });
    }

    // Dropdown
    if (field.validation?.includes('required') && field.type === 'dropdown' && field.value === '') {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
            path: ['fields', index, 'value'],
        });
    }
};

const validateRequiredIf = ({ fields, field, index, ctx, t }: ValidationContext) => {
    const requiredIf = field.validation?.find((v) => v.startsWith('required_if:'));

    if (requiredIf) {
        // Split by ':' to separate the field name from the values
        const [_validationType, rest] = requiredIf.split(':');

        // Split the rest by ',' to get an array of values
        const [fieldName, ...values] = rest?.split(',') || [];

        // Since the values are enclosed in quotes, we need to remove them
        const cleanedValues = values.map((value) => value.replace(/"/g, ''));

        // Find the field by field name
        const fieldToCheck = fields?.find((f) => f.key === fieldName);

        // Check if the field has one of the cleanedValues and the requiredIf field is empty
        // The value can also be an array, so we need to check if the value is included in the array
        if (
            fieldToCheck &&
            Array.isArray(fieldToCheck.value) &&
            fieldToCheck.value.some((v) => cleanedValues.includes(v)) &&
            typeof field.value === 'string' &&
            !field.value.trim()
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
                path: ['fields', index, 'value'],
            });
        }

        if (
            fieldToCheck &&
            cleanedValues.includes(fieldToCheck.value as string) &&
            typeof field.value === 'string' &&
            !field.value.trim()
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateRequiredWithout = ({ fields, field, index, ctx, t }: ValidationContext) => {
    const requiredWithout = field.validation?.find((v) => v.startsWith('required_without:'));

    if (requiredWithout) {
        // Split by ':' to separate the field name from the values
        const [_validationType, fieldName] = requiredWithout.split(':');

        // Find the field by field name
        const fieldToCheck = fields?.find((f) => f.key === fieldName);

        // If the fieldToCheck has no value, the field is required
        if (!fieldToCheck?.value && typeof field.value === 'string' && !field.value.trim()) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateEmail = ({ field, index, ctx, t }: ValidationContext) => {
    if (
        field.validation?.includes('email') &&
        typeof field.value === 'string' &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required_email' as any) ?? 'Niet een valide e-mail',
            path: ['fields', index, 'value'],
        });
    }
};

const validateNumber = ({ field, index, ctx, t }: ValidationContext) => {
    if (field.validation?.includes('number') && isNaN(Number(field.value))) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required_number' as any) ?? 'Dit is geen nummer',
            path: ['fields', index, 'value'],
        });
    }
};

const isValidDate = (date: string, format = 'dd-MM-yyyy') => {
    const parsedDate = parse(date, format, new Date());
    return isValid(parsedDate);
};

const validateDate = ({ field, index, ctx, t }: ValidationContext) => {
    if (
        field.validation?.includes('date') &&
        typeof field.value === 'string' &&
        // Check if the value is not empty and if it is a valid date
        field.value.trim() &&
        !isValidDate(field.value)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required_date' as any) ?? 'Dit is geen valide datum',
            path: ['fields', index, 'value'],
        });
    }
};

const validateString = ({ field, index, ctx, t }: ValidationContext) => {
    if (
        field.validation?.includes('string') &&
        typeof field.value === 'string' &&
        !field.value.trim()
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t?.('form_field_required' as any) ?? 'Dit veld is verplicht',
            path: ['fields', index, 'value'],
        });
    }
};

const validateBeforeDate = ({ field, index, ctx, t }: ValidationContext) => {
    const beforeDate = field.validation?.find((v) => v.startsWith('before:'));
    if (beforeDate) {
        // TODO: double check if the format is correct
        const beforeDateParsed = parse(String(beforeDate.split(':')[1]), 'yyyy-MM-dd', new Date());
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        if (field.value && valueDateParsed > beforeDateParsed) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_date_before' as any, {
                        date: format(beforeDateParsed, 'dd-MM-yyyy'),
                    }) ?? `De datum moet voor ${format(beforeDateParsed, 'dd-MM-yyyy')} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateBeforeAndEqualDate = ({ field, index, ctx, t }: ValidationContext) => {
    const beforeAndEqualDate = field.validation?.find((v) => v.startsWith('before_or_equal:'));
    if (beforeAndEqualDate) {
        const beforeAndEqualDateParsed = parse(
            String(beforeAndEqualDate.split(':')[1]),
            'yyyy-MM-dd',
            new Date(),
        );
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        // Check if the date is on or before the specified date
        const isBeforeOrEqual =
            isBefore(valueDateParsed, beforeAndEqualDateParsed) ||
            isEqual(valueDateParsed, beforeAndEqualDateParsed);

        if (field.value && !isBeforeOrEqual) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_date_before_or_equal' as any, {
                        date: format(beforeAndEqualDateParsed, 'dd-MM-yyyy'),
                    }) ??
                    `De datum moet voor of op ${format(beforeAndEqualDateParsed, 'dd-MM-yyyy')} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateAfterDate = ({ field, index, ctx, t }: ValidationContext) => {
    const afterDate = field.validation?.find((v) => v.startsWith('after:'));
    if (afterDate) {
        const afterDateParsed = parse(String(afterDate.split(':')[1]), 'yyyy-MM-dd', new Date());
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        if (field.value && valueDateParsed < afterDateParsed) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_date_after' as any, {
                        date: format(afterDateParsed, 'dd-MM-yyyy'),
                    }) ?? `De datum moet na ${format(afterDateParsed, 'dd-MM-yyyy')} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateAfterAndEqualDate = ({ field, index, ctx, t }: ValidationContext) => {
    const afterAndEqualDate = field.validation?.find((v) => v.startsWith('after_or_equal:'));
    if (afterAndEqualDate) {
        const afterAndEqualDateParsed = parse(
            String(afterAndEqualDate.split(':')[1]),
            'yyyy-MM-dd',
            new Date(),
        );
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        const isAfterOrEqual =
            isAfter(valueDateParsed, afterAndEqualDateParsed) ||
            isEqual(valueDateParsed, afterAndEqualDateParsed);

        if (field.value && !isAfterOrEqual) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_date_after_or_equal' as any, {
                        date: format(afterAndEqualDateParsed, 'dd-MM-yyyy'),
                    }) ??
                    `De datum moet na of op ${format(afterAndEqualDateParsed, 'dd-MM-yyyy')} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateMinLength = ({ field, index, ctx, t }: ValidationContext) => {
    const minLength = field.validation?.find((v) => v.startsWith('min:'));
    if (minLength) {
        const length = Number(minLength.split(':')[1]);

        if (field.value && typeof field.value === 'string' && field.value.length < length) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_min' as any, {
                        min: length.toString(),
                    }) ?? `Dit veld moet minimaal ${length.toString()} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

const validateMaxLength = ({ field, index, ctx, t }: ValidationContext) => {
    const maxLength = field.validation?.find((v) => v.startsWith('max:'));
    if (maxLength) {
        const length = Number(maxLength.split(':')[1]);

        if (field.value && typeof field.value === 'string' && field.value.length > length) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    t?.('form_field_required_max' as any, {
                        max: length.toString(),
                    }) ?? `Dit veld mag maximaal ${length.toString()} zijn`,
                path: ['fields', index, 'value'],
            });
        }
    }
};

/**
 * This is used for the form schema
 * It is used to validate the form data
 */
const formSchema = (t?: TFunction) => {
    return z
        .object({
            fields: z.array(fieldSchema).optional(),
        })
        .superRefine((data, ctx) => {
            data.fields?.forEach((field, index) => {
                // Check if the field is required
                validateRequired({ field, index, ctx, t });

                // Check if the field is required if another field has a specific value
                validateRequiredIf({ fields: data.fields, field, index, ctx, t });

                // Check if the field is required unless another field has a specific value
                validateRequiredWithout({ fields: data.fields, field, index, ctx, t });

                // Check if the field is an email
                validateEmail({ field, index, ctx, t });

                // Check if the field is a number
                validateNumber({ field, index, ctx, t });

                // Check if the field is a valid date
                validateDate({ field, index, ctx, t });

                // Check if the field is a string
                validateString({ field, index, ctx, t });

                // Check if the date is before the specified date
                validateBeforeDate({ field, index, ctx, t });

                // Check if the date is after the specified date
                validateAfterDate({ field, index, ctx, t });

                // Check if the date is before or equal to the specified date
                validateBeforeAndEqualDate({ field, index, ctx, t });

                // Check if the date is after or equal to the specified date
                validateAfterAndEqualDate({ field, index, ctx, t });

                // Check if the field has a minimum length
                validateMinLength({ field, index, ctx, t });

                // Check if the field has a maximum length
                validateMaxLength({ field, index, ctx, t });
            });
        });
};
const form = formSchema();
type FormSchemaType = z.infer<typeof form>;

const groupSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullable(),
    fields: z.array(fieldSchema),
});
type GroupSchemaType = z.infer<typeof groupSchema>;

const stepSchema = z.object({
    id: z.number(),
    title: z.string(),
    description: z.string().nullable(),
    groups: z.array(groupSchema),
});
type StepSchemaType = z.infer<typeof stepSchema>;

const registrationStepsSchema = stepSchema;
type RegistrationStepsSchema = z.infer<typeof registrationStepsSchema>;

export {
    registrationStepsSchema,
    groupSchema,
    fieldSchema,
    formSchema,
    stepSchema,
    type GroupSchemaType,
    type RegistrationStepsSchema,
    type FieldSchemaType,
    type FormSchemaType,
    type StepSchemaType,
};
