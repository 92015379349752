import { Label, Text } from '@i2e/components';
import { FieldSchemaType } from './form-switch-staff-schema';
import InputField from '../input-field';
import TextareaField from '../textarea-field';
import CheckboxField from '../checkbox-field';
import RadioButtonsField from '../radio-buttons-field';
import DatePickerField from '../date-picker-field';
import CheckboxesField from '../checkboxes-field';
import DropdownField from '../dropdown-field';
import CountriesField from '../countries-field';

import { checkRequired, factoryDisabledDates } from './helpers';

export const FormSwitchStaff = ({
    fields,
    hiddenFields,
    customOptions,
}: {
    fields: FieldSchemaType[];
    hiddenFields: Set<string>;
    customOptions?: Record<string, unknown[]>;
}) => {
    return (
        <>
            {fields.map((item, index) => {
                // Check if required is in the validation array
                const required = checkRequired(item);

                if (hiddenFields.has(item.key)) {
                    return null;
                }

                // Add a switch case to load the correct component based on the type of field
                switch (item.type) {
                    case 'description':
                        return (
                            <div className="flex flex-col space-y-2" key={item.key}>
                                <Label>{item.label}</Label>
                                <Text>{item.description}</Text>
                            </div>
                        );
                    case 'text':
                        return (
                            <InputField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'textarea':
                        return (
                            <TextareaField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'checkbox':
                        return (
                            <CheckboxField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'radiobuttons':
                        return (
                            <RadioButtonsField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'datepicker':
                        return (
                            <DatePickerField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={`fields.${index}.value`}
                                disabledDates={factoryDisabledDates(item.validation)}
                            />
                        );
                    case 'checkboxes':
                        return (
                            <CheckboxesField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'dropdown':
                        return (
                            <DropdownField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                options={item.options}
                                name={`fields.${index}.value`}
                            />
                        );
                    case 'countries':
                        return (
                            <CountriesField
                                rules={{ required }}
                                {...item}
                                key={item.key}
                                name={`fields.${index}.value`}
                                countries={customOptions?.countries}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};
