import { Label, RadioGroup, RadioGroupItem } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';

export interface RadioButtonsFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description: string | null;
    options?: Record<string, string>[];
}

const RadioButtonsField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    options,
    ...props
}: RadioButtonsFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => (
                <FormItem className="space-y-2">
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup
                        className="flex flex-col max-md:space-y-4 md:space-y-1"
                        defaultValue={value as string}
                        onValueChange={(val) => {
                            field.onChange(val);
                        }}
                    >
                        {options?.map(({ key, value }) => (
                            <FormItem key={key}>
                                <Label className="flex flex-row items-center space-x-1.5 space-y-0">
                                    <FormControl>
                                        <RadioGroupItem value={key || ''} />
                                    </FormControl>
                                    <span className="text-sm font-normal text-neutral-250 hover:cursor-pointer">
                                        {value}
                                    </span>
                                </Label>
                            </FormItem>
                        ))}
                    </RadioGroup>
                    <FormDescription>{description}</FormDescription>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default RadioButtonsField;
