'use client';

import {
    InputText,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    cn,
} from '@i2e/components';
import { useEffect, useRef, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';

import './color-picker-field.css';
import { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { useDebounce, useLocalStorage } from 'react-use';

import { FormControl, FormField, FormItem } from '../form-fields';

const DEBOUNCE_TIME = 1000;
const LOCAL_STORAGE_KEY = 'recentColors';
const DEFAULT_COLOR = '#ffffff';

export type ColorPickerFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const ColorPickerField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ColorPickerFieldProps<TFieldValues, TName>) => {
    const fieldRef = useRef<ControllerRenderProps<TFieldValues, TName>>();
    const [isOpen, setIsOpen] = useState(false);
    const [colorType, setColorType] = useState('Hex');
    const [color, setColor] = useState(props.defaultValue ?? DEFAULT_COLOR);
    const [hexInput, setHexInput] = useState(color.slice(1, 7));
    const [alphaInput, setAlphaInput] = useState(getAlphaFromColor(color).toString());
    const [recentColors, setRecentColors] = useLocalStorage<string[]>(LOCAL_STORAGE_KEY, []);

    const handleColorChange = (newColor: string) => {
        setColor(newColor);
        setHexInput(newColor.slice(1, 7));
        setAlphaInput(getAlphaFromColor(newColor).toString());
    };

    const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newHex = e.target.value;
        setHexInput(newHex);
        if (/^[0-9A-Fa-f]{6}$/.test(newHex)) {
            const fullHex = `#${newHex}`;
            handleColorChange(fullHex + (color.length > 7 ? color.slice(7) : ''));
        }
    };

    const handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAlpha = e.target.value.replace('%', '');
        setAlphaInput(newAlpha);

        if (/^\d{1,3}$/.test(newAlpha)) {
            const alphaValue = Math.min(100, Math.max(0, parseInt(newAlpha, 10)));

            if (alphaValue === 100) {
                handleColorChange(color.slice(0, 7));
            } else {
                const alphaHex = Math.round((alphaValue / 100) * 255)
                    .toString(16)
                    .padStart(2, '0');
                handleColorChange(color.slice(0, 7) + alphaHex);
            }
        }
    };

    const getRFromColor = (colorString: string): number => getRGBFromColor(colorString)[0];
    const getGFromColor = (colorString: string): number => getRGBFromColor(colorString)[1];
    const getBFromColor = (colorString: string): number => getRGBFromColor(colorString)[2];

    const handleRGBChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = Math.min(255, Math.max(0, parseInt(value, 10)));
        const [r, g, b] = getRGBFromColor(color);
        let newColor: string;

        switch (name) {
            case 'r':
                newColor = `#${numValue.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
                break;
            case 'g':
                newColor = `#${r.toString(16).padStart(2, '0')}${numValue.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
                break;
            case 'b':
                newColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${numValue.toString(16).padStart(2, '0')}`;
                break;
            default:
                return;
        }

        handleColorChange(newColor + (color.length > 7 ? color.slice(7) : ''));
    };

    // If the default value changes, update the color
    useEffect(() => {
        setColor(props.defaultValue ?? DEFAULT_COLOR);
    }, [props.defaultValue]);

    // Debounce the color change to prevent rapid updates
    useDebounce(
        () => {
            fieldRef.current?.onChange(color);

            if (color) {
                setRecentColors((prev) => {
                    const updatedColors = [
                        color,
                        ...(prev ?? []).filter((c) => c !== color).slice(0, 6),
                    ];
                    return updatedColors;
                });
            }
        },
        DEBOUNCE_TIME,
        [color],
    );

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                <button
                    className="size-11 rounded-lg border bg-white"
                    type="button"
                    style={{
                        backgroundColor: color,
                    }}
                />
            </PopoverTrigger>
            <PopoverContent className="w-70">
                <FormField
                    {...props}
                    render={({ field }) => {
                        fieldRef.current = field;

                        return (
                            <FormItem>
                                <FormControl>
                                    <div>
                                        <HexAlphaColorPicker
                                            color={color}
                                            onChange={handleColorChange}
                                            className="in2event-color-picker"
                                        />
                                        <div className="mt-2 flex min-w-0 gap-2">
                                            <div className="w-28 grow">
                                                <Select
                                                    variant="compact"
                                                    className="w-full"
                                                    value={colorType}
                                                    onChange={(e) => setColorType(e.target.value)}
                                                >
                                                    <option value="Hex">Hex</option>
                                                    <option value="RGB">RGB</option>
                                                </Select>
                                            </div>
                                            <div
                                                className={cn('grow', {
                                                    hidden: colorType !== 'Hex',
                                                })}
                                            >
                                                <div className="relative">
                                                    <div className="text-neutral-1100 absolute left-2.5 top-1.5 font-bold">
                                                        #
                                                    </div>
                                                    <InputText
                                                        name="hex"
                                                        value={hexInput}
                                                        onChange={handleHexChange}
                                                        className="w-full pl-6"
                                                        placeholder="Hex"
                                                        variant="compact"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={cn('flex gap-2', {
                                                    hidden: colorType !== 'RGB',
                                                })}
                                            >
                                                <InputText
                                                    name="r"
                                                    value={getRFromColor(color)}
                                                    onChange={handleRGBChange}
                                                    className="w-9 px-1"
                                                    variant="compact"
                                                />
                                                <InputText
                                                    name="g"
                                                    value={getGFromColor(color)}
                                                    onChange={handleRGBChange}
                                                    className="w-9 px-1"
                                                    variant="compact"
                                                />
                                                <InputText
                                                    name="b"
                                                    value={getBFromColor(color)}
                                                    onChange={handleRGBChange}
                                                    className="w-9 px-1"
                                                    variant="compact"
                                                />
                                            </div>
                                            <div className="w-28">
                                                <InputText
                                                    name="alpha"
                                                    value={`${alphaInput}%`}
                                                    onChange={handleAlphaChange}
                                                    className="w-full"
                                                    placeholder="Alpha"
                                                    variant="compact"
                                                />
                                            </div>
                                        </div>
                                        {recentColors && recentColors.length > 0 ? (
                                            <div className="mt-4">
                                                <h3 className="mb-2 text-sm font-medium">
                                                    Recently used
                                                </h3>
                                                <div className="flex flex-wrap justify-between">
                                                    {recentColors.map((recentColor) => (
                                                        <button
                                                            type="button"
                                                            key={recentColor}
                                                            className={cn(
                                                                'size-6 rounded-md border',
                                                                {
                                                                    'shadow-[0px_0px_0px_2px_#00000040]':
                                                                        recentColor === color,
                                                                },
                                                            )}
                                                            style={{ backgroundColor: recentColor }}
                                                            onClick={() =>
                                                                handleColorChange(recentColor)
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                        <InputText {...field} className="hidden" />
                                    </div>
                                </FormControl>
                            </FormItem>
                        );
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};

const getAlphaFromColor = (colorString: string) => {
    if (colorString.length === 7) return 100; // Fully opaque
    return Math.round((parseInt(colorString.slice(7), 16) / 255) * 100);
};

const getRGBFromColor = (colorString: string): [number, number, number] => {
    const hex = colorString.slice(1, 7);
    return [
        parseInt(hex.slice(0, 2), 16),
        parseInt(hex.slice(2, 4), 16),
        parseInt(hex.slice(4, 6), 16),
    ];
};
