import { InputText } from '@i2e/components';
import { VisibilityIcon, VisibilityOffIcon } from '@in2event/icons';
import type { ComponentPropsWithoutRef } from 'react';
import { useState } from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../form-fields';

interface PasswordFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    placeholder?: string;
    inputProps?: Omit<ComponentPropsWithoutRef<typeof InputText>, 'name' | 'label' | 'placeholder'>;
}

const PasswordField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    placeholder,
    inputProps,
    ...props
}: PasswordFieldProps<TFieldValues, TName>) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormField
            {...props}
            render={({ field }) => (
                <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <div className="relative">
                        <FormControl>
                            <InputText
                                type={showPassword ? 'text' : 'password'}
                                placeholder={placeholder}
                                {...field}
                                {...inputProps}
                            />
                        </FormControl>
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 flex items-center pr-2"
                            onClick={togglePasswordVisibility}
                            tabIndex={-1}
                        >
                            {showPassword ? (
                                <VisibilityOffIcon
                                    className="size-5"
                                    fill="#171717"
                                    fillOpacity={0.64}
                                />
                            ) : (
                                <VisibilityIcon
                                    className="size-5"
                                    fill="#171717"
                                    fillOpacity={0.64}
                                />
                            )}
                        </button>
                    </div>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default PasswordField;
