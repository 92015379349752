import { isValid, parse } from 'date-fns';
import * as z from 'zod';

const fieldOptionsSchema = z.array(
    z.object({
        key: z.string(),
        value: z.string(),
    }),
);
type FormFieldOptionsType = z.infer<typeof fieldOptionsSchema>;

const fieldTypeSchema = z.enum([
    'dob',
    'input', // 'input' is the same as 'text
    'description', // 'description' is the same as 'text
    'text',
    'textarea',
    'checkbox',
    'radiobuttons',
    'datepicker',
    'checkboxes',
    'dropdown',
    'avatar',
]);
type FormFieldType = z.infer<typeof fieldTypeSchema>;

const validation = z
    .enum(['required', 'email', 'number', 'date', 'string', ''])
    .or(
        z
            .string()
            .refine(
                (v) =>
                    v.startsWith('before:') ||
                    v.startsWith('after:') ||
                    v.startsWith('required_if:'),
            ),
    );

const fieldSchema = z.object({
    type: fieldTypeSchema.nullable(),
    key: z.string(),
    label: z.string(),
    description: z.string().nullable(),
    value: z.any().optional(),
    validation: validation.array().nullable(),
    options: fieldOptionsSchema.optional(),
});
type FieldSchemaType = z.infer<typeof fieldSchema>;

interface ValidationContext {
    fieldIdentifier: string;
    message?: string;
    fields?: FieldSchemaType[];
    field: FieldSchemaType;
    index: number;
    ctx: z.RefinementCtx;
}

const validateRequired = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    // Text and textarea
    if (
        field.validation?.includes('required') &&
        typeof field.value === 'string' &&
        !field.value.trim()
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This field is required',
            path: [fieldIdentifier, index, 'value'],
        });
    }
    // Multiple checkboxes
    if (
        field.validation?.includes('required') &&
        field.type === 'checkboxes' &&
        Array.isArray(field.value) &&
        field.value.length === 0
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'At least one option is required',
            path: [fieldIdentifier, index, 'value'],
        });
    }
    // Single checkbox
    if (field.validation?.includes('required') && field.type === 'checkbox' && !field.value) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This field is required',
            path: [fieldIdentifier, index, 'value'],
        });
    }

    // Dropdown
    if (field.validation?.includes('required') && field.type === 'dropdown' && field.value === '') {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This field is required',
            path: [fieldIdentifier, index, 'value'],
        });
    }
};

const validateRequiredIf = ({
    fieldIdentifier,
    message,
    fields,
    field,
    index,
    ctx,
}: ValidationContext) => {
    const requiredIf = field.validation?.find((v) => v.startsWith('required_if:'));

    if (requiredIf) {
        // Split by ':' to separate the field name from the values
        const [_validationType, rest] = requiredIf.split(':');

        // Split the rest by ',' to get an array of values
        const [fieldName, ...values] = rest?.split(',') || [];

        // Since the values are enclosed in quotes, we need to remove them
        const cleanedValues = values.map((value) => value.replace(/"/g, ''));

        // Find the field by field name
        const fieldToCheck = fields?.find((f) => f.key === fieldName);

        // Check if the field has one of the cleanedValues and the requiredIf field is empty
        if (
            fieldToCheck &&
            cleanedValues.includes(fieldToCheck.value as string) &&
            typeof field.value === 'string' &&
            !field.value.trim()
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: message ?? 'This field is required',
                path: [fieldIdentifier, index, 'value'],
            });
        }
    }
};

const validateEmail = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    if (
        field.validation?.includes('email') &&
        typeof field.value === 'string' &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This is not a valid email',
            path: [fieldIdentifier, index, 'value'],
        });
    }
};

const validateNumber = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    if (field.validation?.includes('number') && isNaN(Number(field.value))) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This is not a valid number',
            path: [fieldIdentifier, index, 'value'],
        });
    }
};

const isValidDate = (date: string, format = 'dd-MM-yyyy') => {
    const parsedDate = parse(date, format, new Date());
    return isValid(parsedDate);
};

const validateDate = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    if (
        field.validation?.includes('date') &&
        typeof field.value === 'string' &&
        !isValidDate(field.value)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This is not a valid date',
            path: [fieldIdentifier, index, 'value'],
        });
    }
};

const validateString = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    if (
        field.validation?.includes('string') &&
        typeof field.value === 'string' &&
        !field.value.trim()
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: message ?? 'This is not a valid string',
            path: [fieldIdentifier, index, 'value'],
        });
    }
};

const validateBeforeDate = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    const beforeDate = field.validation?.find((v) => v.startsWith('before:'));
    if (beforeDate) {
        // TODO: double check if the format is correct
        const beforeDateParsed = parse(String(beforeDate.split(':')[1]), 'yyyy-MM-dd', new Date());
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        if (field.value && valueDateParsed > beforeDateParsed) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: message ?? 'This date is after the specified date',
                path: [fieldIdentifier, index, 'value'],
            });
        }
    }
};

const validateAfterDate = ({ fieldIdentifier, message, field, index, ctx }: ValidationContext) => {
    const afterDate = field.validation?.find((v) => v.startsWith('after:'));
    if (afterDate) {
        const afterDateParsed = parse(String(afterDate.split(':')[1]), 'yyyy-MM-dd', new Date());
        const valueDateParsed = parse(String(field.value), 'dd-MM-yyyy', new Date());

        if (field.value && valueDateParsed < afterDateParsed) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: message ?? 'This date is before the specified date',
                path: [fieldIdentifier, index, 'value'],
            });
        }
    }
};

export {
    fieldSchema,
    type FormFieldType,
    fieldTypeSchema,
    type FieldSchemaType,
    fieldOptionsSchema,
    type FormFieldOptionsType,
    validateRequired,
    validateRequiredIf,
    validateEmail,
    validateNumber,
    validateDate,
    validateString,
    validateBeforeDate,
    validateAfterDate,
};
