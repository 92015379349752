import { Checkbox, EventDate, cn } from '@i2e/components';
import { Locale, format, isValid, parse } from 'date-fns';
import { de, enUS, nl } from 'date-fns/locale';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormLabel } from '..';

export interface AvailabilityCheckboxFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    date: EventDate;
    parseFormat?: string;
    beautifyFormat?: string;
    locale?: string;
    label?: string;
}

const AvailabilityCheckboxField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    date,
    parseFormat = 'yyyy-MM-dd',
    beautifyFormat = 'E d MMM',
    locale = 'en',
    disabled,
    label,
    ...props
}: AvailabilityCheckboxFieldProps<TFieldValues, TName>) => {
    const dateString = date.startDateTime.split('T')[0];

    if (!dateString) {
        throw new Error('Invalid date string');
    }

    const startDateParsed = parse(dateString, parseFormat, new Date());

    if (!isValid(startDateParsed)) {
        throw new Error(`Invalid date: ${dateString}`);
    }

    const getLocale = (): Locale => {
        switch (locale) {
            case 'en':
                return enUS;
            case 'nl':
                return nl;
            case 'de':
                return de;
            default:
                return enUS;
        }
    };

    const startDateFormatted = format(startDateParsed, beautifyFormat, { locale: getLocale() });

    const handleOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: { onChange: (value: number[]) => void; value: number[] },
    ) => {
        e.target.checked
            ? field.onChange([...field.value, date.id])
            : field.onChange(field.value.filter((value) => value !== date.id));
    };

    return (
        <FormField
            {...props}
            render={({ field }) => {
                return (
                    <FormItem className="flex w-fit flex-row items-center space-x-2 whitespace-nowrap">
                        <FormControl>
                            <Checkbox
                                checked={field.value.includes(date.id)}
                                onChange={(e) => handleOnChange(e, field)}
                                disabled={disabled}
                                className="hover:cursor-pointer"
                            />
                        </FormControl>

                        <FormLabel
                            className={cn('hover:cursor-pointer', {
                                'rounded bg-blue-200 px-1.5 text-[13px] font-bold leading-5 text-blue-600':
                                    date.isShowday,
                                'text-neutral-250 text-[13px] font-normal leading-5':
                                    !date.isShowday,
                            })}
                        >
                            {label ?? startDateFormatted}
                        </FormLabel>
                    </FormItem>
                );
            }}
        />
    );
};

export default AvailabilityCheckboxField;
