import { Checkbox, Label } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';

export interface CheckboxesFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description: string | null;
    options?: Record<string, string>[];
}

const CheckboxesField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    options,
    ...props
}: CheckboxesFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field }) => {
                // Handle checked change
                const onCheckedChange = (checked: boolean, option: string) => {
                    if (checked) {
                        // If the checkbox is checked, add the value to the array
                        field.onChange([...(field.value as string[]), option]);
                    } else {
                        // If the checkbox is unchecked, remove the value from the array
                        // We do this by filtering the array and removing the unchecked value
                        // We then update the value in the form
                        field.onChange(
                            (field.value as string[]).filter((value: string) => value !== option),
                        );
                    }
                };

                const isChecked = (option: string): boolean =>
                    Boolean(
                        field.value && Array.isArray(field.value) && field.value.includes(option),
                    );

                return (
                    <FormItem>
                        <div className="mb-2">
                            <FormLabel>{label}</FormLabel>
                        </div>
                        <div className="grid grid-cols-3 gap-2 max-md:grid-cols-2 max-md:gap-y-4">
                            {options?.map(({ key, value }) => (
                                <FormItem key={key}>
                                    <Label className="flex items-center space-x-2 hover:cursor-pointer">
                                        <FormControl>
                                            <Checkbox
                                                checked={isChecked(key || '')}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    onCheckedChange(checked, key || '');
                                                }}
                                                className="hover:cursor-pointer"
                                            />
                                        </FormControl>
                                        <span className="whitespace-nowrap text-sm font-normal text-neutral-250">
                                            {value}
                                        </span>
                                    </Label>
                                </FormItem>
                            ))}
                        </div>
                        <FormDescription>{description}</FormDescription>
                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};

export default CheckboxesField;
