import { useEffect, useState } from 'react';

export const useMenuPortalConfig = (menuPortalTarget: HTMLElement | null) => {
    const [menuPortalConfig, setMenuPortalConfig] = useState({});

    useEffect(() => {
        // This code runs after the component is mounted, ensuring 'document' is defined
        if (typeof document !== 'undefined') {
            setMenuPortalConfig({
                menuPortalTarget: menuPortalTarget || document.body,
                menuPosition: 'fixed',
            });
        }
    }, [menuPortalTarget]); // Empty dependency array means this effect runs once on mount

    return menuPortalConfig;
};
