import { Textarea } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';

export interface TextareaFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description?: string | null;
    placeholder?: string | null;
    className?: string;
}

const TextareaField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    placeholder,
    className,
    ...props
}: TextareaFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => (
                <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                        <Textarea
                            {...field}
                            className={className}
                            value={value as string}
                            placeholder={placeholder ?? ''}
                        />
                    </FormControl>
                    <FormDescription>{description}</FormDescription>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default TextareaField;
