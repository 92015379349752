import {
    Avatar,
    AvatarImage,
    AvatarRoot,
    Button,
    Heading,
    InputText,
    Loading,
    Text,
    cn,
} from '@i2e/components';
import { DeleteIcon } from '@in2event/icons';
import { ReactNode, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { ControllerProps, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormMessage } from '..';

export interface UploaderFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    uploadUrl: string;
    message?: Record<string, ReactNode>;
    description?: string | null;
}

const UploaderField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    uploadUrl,
    message,
    ...props
}: UploaderFieldProps<TFieldValues, TName>) => {
    const form = useFormContext<TFieldValues>();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [avatar, setAvatar] = useState<{
        name: string;
        url: string;
        size: number;
    }>();

    const onFileChange = async (
        files: FileList | File[],
    ): Promise<{
        success: boolean;
        id?: string;
    }> => {
        setIsLoading(true);

        const file = files[0];

        if (!file) {
            const { noFileSelected } = message ?? {};
            const errorMessage =
                noFileSelected && typeof noFileSelected === 'string'
                    ? noFileSelected
                    : 'No file selected';

            form.setError(props.name, {
                type: 'manual',
                message: errorMessage,
            });

            return {
                success: false,
            };
        }

        try {
            setAvatar({
                name: file.name,
                url: URL.createObjectURL(file),
                size: file.size,
            });

            const { data } = await Avatar.uploader(uploadUrl, file);

            if (!data) {
                const { uploadFailed } = message || {};
                const errorMessage =
                    uploadFailed && typeof uploadFailed === 'string'
                        ? uploadFailed
                        : 'Upload failed';
                throw new Error(errorMessage);
            }

            setAvatar({
                name: data.fileName,
                url: data.attachmentUrl,
                size: data.fileSize,
            });

            return {
                success: true,
                id: data.id.toString(),
            };
        } catch (error) {
            setAvatar(undefined);

            const { uploadFailed } = message || {};
            const errorMessage =
                uploadFailed && typeof uploadFailed === 'string' ? uploadFailed : 'Upload failed';

            form.setError(props.name, {
                type: 'manual',
                message: errorMessage,
            });

            return {
                success: false,
            };
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormField
            {...props}
            render={({ field }) => (
                <FormItem>
                    <Heading variant="title-3" as="h3" className="mb-5">
                        {label}
                    </Heading>

                    {description ? <Text className="mb-5">{description}</Text> : null}

                    <Dropzone
                        onDrop={async (acceptedFiles) => {
                            const { id, success } = await onFileChange(acceptedFiles);

                            if (success && id) {
                                field.onChange(id);
                            }
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <FormControl>
                                <section
                                    className={cn(
                                        'flex w-full items-center justify-between gap-2 rounded-lg border border-dashed border-base-300 p-2',
                                        {
                                            'border-solid': Boolean(avatar),
                                        },
                                    )}
                                >
                                    {avatar ? (
                                        <div className="flex flex-1 items-center gap-3">
                                            <AvatarRoot className="relative size-12 items-center justify-center">
                                                {isLoading ? (
                                                    <div className="absolute inset-0 z-10 flex items-center justify-center bg-neutral-60">
                                                        <Loading className="size-5" />
                                                    </div>
                                                ) : (
                                                    <AvatarImage
                                                        src={avatar.url}
                                                        alt={avatar.name}
                                                    />
                                                )}
                                            </AvatarRoot>
                                            <div className="space-y-1">
                                                <Text>{avatar.name}</Text>
                                                <Text variant="caption">
                                                    {(avatar.size / 1024).toFixed(2)} KB
                                                </Text>
                                            </div>
                                        </div>
                                    ) : null}

                                    {!avatar && (
                                        <div {...getRootProps()} className="flex-1">
                                            <input {...getInputProps()} />
                                            <div className="flex h-14 w-full items-center justify-center gap-2 hover:cursor-pointer">
                                                <Text
                                                    variant="body-small"
                                                    className="text-center font-medium text-neutral-250"
                                                >
                                                    {message?.uploadMessage ||
                                                        'Drop file here or click to upload'}
                                                </Text>
                                            </div>
                                        </div>
                                    )}

                                    {avatar ? (
                                        <Button
                                            variant="subtle"
                                            type="button"
                                            size="icon"
                                            onClick={() => {
                                                setAvatar(undefined);
                                                // Empty out fileInputRef to allow re-uploading the same file
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = '';
                                                }
                                                // Reset field value
                                                field.onChange('');
                                            }}
                                        >
                                            <DeleteIcon className="size-5" />
                                        </Button>
                                    ) : null}
                                </section>
                            </FormControl>
                        )}
                    </Dropzone>

                    <InputText {...field} className="hidden" />

                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default UploaderField;
