import { Checkbox, Label } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormMessage } from '..';

export interface CheckboxFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description?: string | null;
}

const CheckboxField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    ...props
}: CheckboxFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => (
                <FormItem>
                    <Label className="flex flex-row items-center space-x-1.5 space-y-0">
                        <FormControl>
                            <Checkbox {...field} checked={value as boolean} />
                        </FormControl>
                        <span className="text-sm font-normal text-neutral-250 hover:cursor-pointer">
                            {label}
                        </span>
                    </Label>
                    <FormDescription>{description}</FormDescription>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default CheckboxField;
