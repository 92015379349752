'use client';

import { cn } from '@i2e/components';
import { CloseIcon, ExpandMoreIcon } from '@in2event/icons';
import type { ClearIndicatorProps, DropdownIndicatorProps } from 'react-select';
import { components } from 'react-select';

export const DropdownIndicator = <K,>({ className, ...props }: DropdownIndicatorProps<K>) => {
    return (
        <components.DropdownIndicator
            {...props}
            className={cn('hover:cursor-pointer', className)}
        >
            <ExpandMoreIcon className="size-5 fill-neutral-60" />
        </components.DropdownIndicator>
    );
};

export const ClearIndicator = <K,>({ className, ...props }: ClearIndicatorProps<K>) => {
    return (
        <components.ClearIndicator
            {...props}
            className={cn('hover:cursor-pointer', className)}
        >
            <CloseIcon className="size-5 fill-neutral-60" />
        </components.ClearIndicator>
    );
};
