import { InputText } from '@i2e/components';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

export interface InputFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    className?: string;
    description?: string | null;
    placeholder?: string | null;
    t?: (key: string) => string;
}

const InputField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    className,
    placeholder,
    t,
    ...props
}: InputFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => (
                <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                        <InputText
                            {...field}
                            value={value as string}
                            placeholder={placeholder || ''}
                            autoComplete={label ?? ''}
                            className={className}
                        />
                    </FormControl>
                    <FormDescription>{description}</FormDescription>
                    <FormMessage t={t} />
                </FormItem>
            )}
        />
    );
};

export default InputField;
