import { Checkbox, EventDate } from '@i2e/components';
import { FormControl, FormField, FormItem, FormLabel } from '..';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

export interface AvailabilitySelectAllFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string;
    dates: EventDate[];
    selectedDates: number[];
}

interface FieldData {
    onChange: (value: number[]) => void;
    value: number[];
}

const AvailabilitySelectAllField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    dates,
    selectedDates,
    disabled,
    ...props
}: AvailabilitySelectAllFieldProps<TFieldValues, TName>) => {
    const ids = dates.map((date) => date.id);

    // Check if there are any disabled dates
    const hasDisabledDates = dates.some((date) => date.disabled);

    // If all the [ids] are included in the [selectedDates], then [allChecked] is true
    // Sometimes selectedDates can be undefined, so we need to check if it's not undefined
    const allChecked = selectedDates && ids.every((id) => selectedDates.includes(id));
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, field: FieldData) => {
        e.target.checked
            ? field.onChange([
                  ...field.value,
                  ...dates.filter((date) => !date.disabled).map((item) => item.id),
              ])
            : field.onChange(field.value.filter((value) => !ids.includes(value)));
    };

    return (
        <FormField
            {...props}
            render={({ field }) => {
                return (
                    <FormItem className="flex w-fit flex-row items-center space-x-2 py-2">
                        <FormControl>
                            <Checkbox
                                checked={allChecked}
                                onChange={(e) => handleOnChange(e, field)}
                                disabled={disabled || hasDisabledDates}
                                className="hover:cursor-pointer"
                            />
                        </FormControl>
                        <FormLabel className="text-sm font-semibold leading-4 text-neutral-250 hover:cursor-pointer">
                            {label}
                        </FormLabel>
                    </FormItem>
                );
            }}
        />
    );
};

export default AvailabilitySelectAllField;
