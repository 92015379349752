import { InputDatePicker } from '@i2e/components';
import { isValid, parse } from 'date-fns';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';

export interface DatePickerFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description?: string | null;
    placeholder?: string | null;
    dateFormat?: string;
    disabledDates?: {
        before?: Date;
        after?: Date;
    };
}

const defaultDisabledDates = {
    before: new Date('1900-01-01'),
};

/**
 * The isValid function does not return true on a date that is formatted dd-MM-yyyy
 * This function will parse the date and validate it
 * And otherwise return a new Date
 *
 * @param date string
 */
const parseDateAndValidate = (date: string, dateFormat = 'dd-MM-yyy') => {
    const parsedDate = parse(date, dateFormat, new Date());
    return isValid(parsedDate) ? parsedDate : new Date();
};

const DatePickerField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    disabledDates = defaultDisabledDates,
    dateFormat = 'dd-MM-yyyy',
    placeholder,
    ...props
}: DatePickerFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => {
                const date = value ? parseDateAndValidate(value, dateFormat) : undefined;

                const disabledDatesAfter = disabledDates.after
                    ? new Date(disabledDates.after)
                    : null;
                const disabledDatesBefore = disabledDates.before
                    ? new Date(disabledDates.before)
                    : null;

                let defaultMonth = null;
                // If after and before both are set we assume it is a range
                // We set the default month to the the month they can can pick after before (because everything before is disabled)
                if (disabledDatesAfter && disabledDatesBefore) {
                    defaultMonth = disabledDatesBefore;
                }

                // If only after is set we set the default month to 1 month before the after date (because everything after is disabled)
                if (disabledDatesAfter && !disabledDatesBefore) {
                    defaultMonth = disabledDatesAfter;
                    defaultMonth.setMonth(defaultMonth.getMonth() - 1);
                }

                // If only before is set we set the default month to one month after the before date (because everything before is disabled)
                if (!disabledDatesAfter && disabledDatesBefore) {
                    defaultMonth = disabledDatesBefore;
                    defaultMonth.setMonth(defaultMonth.getMonth() + 1);
                }

                return (
                    <FormItem className="md:w-[280px]">
                        <FormLabel>{label}</FormLabel>
                        <FormControl>
                            <InputDatePicker
                                dateFormat={dateFormat}
                                defaultMonth={defaultMonth ?? date}
                                disabledDates={{
                                    before: new Date('1900-01-01'),
                                    ...disabledDates,
                                }}
                                inputValue={value}
                                name={field.name}
                                onBlur={field.onBlur}
                                onValueChange={field.onChange}
                                placeholder={placeholder ?? 'dd-mm-yyyy'}
                            />
                        </FormControl>
                        <FormDescription>{description}</FormDescription>
                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};

export default DatePickerField;
