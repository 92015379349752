'use client';

import { cn } from '@i2e/components';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import Select from 'react-select';

import { FormDescription, FormField, FormItem, FormLabel, FormMessage, useFormField } from '..';
import { ClearIndicator, DropdownIndicator } from '../react-select-components';

interface OptionsType {
    label: string;
    value: string;
}

interface TimePickerFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label?: string | null;
    description?: string | null;
    placeholder?: string;
}

const TimePickerField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    placeholder,
    ...props
}: TimePickerFieldProps<TFieldValues, TName>) => {
    const generateTimeSlots = () => {
        const slots = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                slots.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return slots;
    };

    const options = generateTimeSlots().map((slot) => ({
        label: slot,
        value: slot,
    }));

    // Custom filter option to match the input value and start with the input value
    // This is to make the search more strict [I2EP5-2082]
    const customFilterOption = (
        option: { label: string; value: string },
        inputValue: string,
    ): boolean => {
        const matchesInput = option.label.includes(inputValue);
        const startsWithInput = option.label.startsWith(inputValue);
        return matchesInput && startsWithInput;
    };

    return (
        <FormField
            {...props}
            render={({ field }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { error } = useFormField();

                return (
                    <FormItem>
                        {label ? <FormLabel>{label}</FormLabel> : null}

                        <Select<OptionsType>
                            {...field}
                            instanceId={`time-picker-${props.name}`}
                            isClearable
                            isSearchable
                            options={options}
                            placeholder={placeholder}
                            menuPortalTarget={document.body}
                            menuPosition="absolute"
                            maxMenuHeight={144}
                            components={{
                                DropdownIndicator,
                                ClearIndicator,
                                IndicatorSeparator: null,
                            }}
                            filterOption={customFilterOption}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                                control: (base, { isFocused }) => ({
                                    ...base,
                                    backgroundColor: '#F6F6F6',
                                    borderColor: isFocused
                                        ? 'rgba(0, 87, 255, 1)'
                                        : 'rgba(23, 23, 23, 0.1)',
                                    borderWidth: 2,
                                    outline: 'none',
                                    boxShadow: 'none',
                                    ...(error && {
                                        borderColor: 'rgba(221, 59, 7, 1)',
                                    }),
                                }),
                            }}
                            classNames={{
                                control: ({ isDisabled }) =>
                                    cn(
                                        isDisabled
                                            ? 'bg-neutral-2100 text-neutral-1100 cursor-not-allowed border-none'
                                            : 'cursor-pointer',
                                        'text-neutral-250 text-sm font-medium',
                                    ),
                                option: ({ isFocused, isSelected }) =>
                                    cn(
                                        'text-neutral-250 text-sm font-medium hover:cursor-pointer',
                                        {
                                            'bg-primary-100 text-primary-900':
                                                isFocused || isSelected,
                                        },
                                    ),
                                valueContainer: () => 'text-sm',
                                noOptionsMessage: () => 'text-sm',
                                loadingMessage: () => 'text-sm',
                                menu: () => 'text-sm',
                                menuList: () => 'text-sm',
                                placeholder: () => 'text-sm font-medium text-neutral-50',
                            }}
                        />

                        {description ? <FormDescription>{description}</FormDescription> : null}

                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};

export default TimePickerField;
