import {
    SelectContent,
    SelectIcon,
    SelectItem,
    SelectItemText,
    SelectRoot,
    SelectTrigger,
    SelectValue,
    cn,
} from '@i2e/components';
import { ArrowDropDownIcon } from '@in2event/icons';
import { ReactCountryFlag } from 'react-country-flag';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../form-fields';

interface Country {
    id: number;
    countryIsoCode: string;
    countryCallingCode: string | null;
    countryName: string;
}

interface TelephoneFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    T extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    K extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
    label: string | null;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    telephone: Omit<ControllerProps<TFieldValues, T>, 'render'>;
    countryId: Omit<ControllerProps<TFieldValues, K>, 'render'>;
    countries: Country[];
}

const TelephoneField = <
    TFieldValues extends FieldValues = FieldValues,
    T extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    K extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    placeholder,
    className,
    telephone,
    inputClassName,
    countryId,
    countries,
}: TelephoneFieldProps<TFieldValues, T, K>) => {
    return (
        <FormField
            {...telephone}
            render={() => (
                <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                        <fieldset
                            className={cn(
                                'flex items-center divide-x divide-base-400 overflow-hidden rounded-md border-2 border-neutral-10 focus-within:border-primary-500',
                                className,
                            )}
                        >
                            <FormField
                                {...countryId}
                                render={({ field }) => {
                                    const selected = countries
                                        // .filter((country) => country.countryCallingCode !== null) // TODO: Uncomment this line
                                        .find((country) => country.id.toString() === field.value);

                                    return (
                                        <FormItem>
                                            <SelectRoot
                                                defaultValue={field.value}
                                                onValueChange={field.onChange}
                                            >
                                                <FormControl>
                                                    <SelectTrigger
                                                        disabled={!countries.length} // disabled if countries are empty
                                                        className={cn(
                                                            'w-16 gap-1 rounded-none bg-base-100',
                                                            inputClassName,
                                                        )}
                                                    >
                                                        <SelectValue>
                                                            <ReactCountryFlag
                                                                countryCode={
                                                                    selected?.countryIsoCode ?? 'US'
                                                                }
                                                                svg
                                                                width={20}
                                                                height={15}
                                                                title={
                                                                    selected?.countryIsoCode ?? 'US'
                                                                }
                                                            />
                                                        </SelectValue>
                                                        <SelectIcon asChild>
                                                            <ArrowDropDownIcon className="size-4" />
                                                        </SelectIcon>
                                                    </SelectTrigger>
                                                </FormControl>

                                                <SelectContent className="mt-1 min-w-sm py-2">
                                                    {countries.map((country) => {
                                                        return (
                                                            <SelectItem
                                                                key={country.id}
                                                                value={country.id.toString()}
                                                                className={cn(
                                                                    '[data-state=checked]:bg-primary-200 space-x-2',
                                                                    {
                                                                        'bg-primary-200':
                                                                            field.value ===
                                                                            country.id.toString(),
                                                                    },
                                                                    inputClassName,
                                                                )}
                                                            >
                                                                <ReactCountryFlag
                                                                    countryCode={
                                                                        country.countryIsoCode
                                                                    }
                                                                    svg
                                                                    width={20}
                                                                    height={15}
                                                                    title={country.countryIsoCode}
                                                                    aria-label={country.countryName}
                                                                />
                                                                <SelectItemText>
                                                                    {country.countryName} (
                                                                    {country.countryCallingCode ??
                                                                        'N/A'}
                                                                    )
                                                                </SelectItemText>
                                                            </SelectItem>
                                                        );
                                                    })}
                                                </SelectContent>
                                            </SelectRoot>
                                        </FormItem>
                                    );
                                }}
                            />

                            <FormField
                                {...telephone}
                                render={({ field }) => (
                                    <FormItem className="grow">
                                        <input
                                            className={cn(
                                                'flex h-10 w-full rounded-r-md border-0 bg-base-100 px-3 py-2.5 text-sm text-neutral-250 outline-none',
                                                'disabled:pointer-events-none disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-4 disabled:text-neutral-1100',
                                                inputClassName,
                                            )}
                                            placeholder={placeholder}
                                            onChange={field.onChange}
                                            value={field.value}
                                            ref={field.ref}
                                        />
                                    </FormItem>
                                )}
                            />
                        </fieldset>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export default TelephoneField;
