import {
    SelectContent,
    SelectIcon,
    SelectItem,
    SelectItemText,
    SelectRoot,
    SelectTrigger,
    SelectValue,
} from '@i2e/components';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '..';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { ExpandMoreIcon } from '@in2event/icons';
import { z } from 'zod';

const CountrySchema = z.object({
    id: z.number(),
    countryIsoCode: z.string(),
    countryCallingCode: z.string().nullable(),
    countryName: z.string(),
    continent: z.string().nullable(),
    status: z.number(),
});

const CountriesSchema = z.array(CountrySchema);

export type Country = z.infer<typeof CountrySchema>;

export interface CountriesFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label: string | null;
    description?: string | null;
    countries?: unknown[];
    placeholder?: string | null;
}

const CountriesField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    countries,
    placeholder,
    ...props
}: CountriesFieldProps<TFieldValues, TName>) => {
    // If countries doesn't safeParse from the schema, return null
    const safeParseCountries = CountriesSchema.safeParse(countries);

    if (!safeParseCountries.success) {
        return null;
    }

    return (
        <FormField
            {...props}
            render={({ field }) => (
                <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <SelectRoot value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                            <SelectTrigger className="w-full justify-between">
                                <SelectValue
                                    placeholder={placeholder || 'Select an option'}
                                    aria-label={field.value}
                                >
                                    {
                                        safeParseCountries.data.find(
                                            ({ id }) => id === Number(field.value),
                                        )?.countryName
                                    }
                                </SelectValue>
                                <SelectIcon asChild>
                                    <ExpandMoreIcon className="size-5" />
                                </SelectIcon>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent
                            position="popper"
                            className="max-h-[var(--radix-select-content-available-height)]"
                        >
                            {safeParseCountries.data.map(({ id, countryName }) => (
                                <SelectItem
                                    key={id}
                                    value={id.toString()}
                                    className="[data-state=checked]:bg-primary-200 h-8 font-semibold hover:cursor-pointer hover:text-primary-900"
                                >
                                    <SelectItemText>{countryName}</SelectItemText>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </SelectRoot>
                    <FormDescription>{description}</FormDescription>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

CountriesField.schema = CountriesSchema;
export default CountriesField;
