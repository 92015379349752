'use client';

import {
    TooltipContent,
    TooltipPrimitive,
    TooltipProvider,
    TooltipRoot,
    TooltipTrigger,
    cn,
} from '@i2e/components';
import { CancelCircleSymbol } from '@in2event/icons';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import type {
    GroupBase,
    InputActionMeta,
    MenuPlacement,
    MenuPosition,
    OptionsOrGroups,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { FormDescription, FormField, FormItem, FormLabel, FormMessage, useFormField } from '..';
import { ClearIndicator, DropdownIndicator } from '../react-select-components';

export type AsyncSelectOptionsType = OptionsOrGroups<unknown, GroupBase<unknown>>;

interface AsyncSelectFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    label?: string | null;
    description?: string | null;
    placeholder?: string;
    disabledTooltip?: string;
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
    options?: AsyncSelectOptionsType | undefined;
    defaultOptions?: AsyncSelectOptionsType | boolean | undefined;
    loadOptions?: (inputValue: string, callback: (options: AsyncSelectOptionsType) => void) => void;
    formatOptionLabel?: (option: unknown) => React.ReactNode;
    menuPortalTarget?: HTMLElement | null | undefined;
    menuPlacement?: MenuPlacement | undefined;
    maxMenuHeight?: number | undefined;
    menuPosition?: MenuPosition | undefined;
    isLoading?: boolean;
    isMulti?: boolean;
    cacheOptions?: boolean;
}

const AsyncSelectField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label,
    description,
    placeholder,
    disabledTooltip,
    onInputChange,
    options,
    loadOptions,
    defaultOptions,
    formatOptionLabel,
    menuPortalTarget,
    maxMenuHeight = 144,
    menuPlacement = 'auto',
    menuPosition,
    isLoading,
    isMulti = false,
    ...props
}: AsyncSelectFieldProps<TFieldValues, TName>) => {
    const { error } = useFormField();

    return (
        <FormField
            {...props}
            render={({ field: { value, ...field } }) => {
                const handleOnRemove = (targetValue: string) => {
                    const newValue = value.filter(
                        (option: { value: string }) => option.value !== targetValue,
                    );

                    field.onChange(newValue);
                };

                return (
                    <FormItem>
                        {label ? <FormLabel>{label}</FormLabel> : null}

                        {isMulti ? (
                            <MultiSelectValues
                                value={value}
                                onRemove={handleOnRemove}
                                disabledTooltip={disabledTooltip}
                            />
                        ) : null}

                        <AsyncSelect
                            {...field}
                            value={value as AsyncSelectOptionsType}
                            instanceId={`react-select-${props.name}`}
                            isLoading={isLoading}
                            options={options}
                            defaultOptions={defaultOptions}
                            loadOptions={loadOptions}
                            onInputChange={onInputChange}
                            placeholder={placeholder}
                            formatOptionLabel={formatOptionLabel}
                            menuPortalTarget={menuPortalTarget}
                            maxMenuHeight={maxMenuHeight}
                            menuPlacement={menuPlacement}
                            menuPosition={menuPosition}
                            components={{
                                DropdownIndicator,
                                ClearIndicator,
                                IndicatorSeparator: null,
                            }}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                                control: (base, { isFocused }) => ({
                                    ...base,
                                    backgroundColor: '#F6F6F6',
                                    borderColor: isFocused
                                        ? 'rgba(0, 87, 255, 1)'
                                        : 'rgba(23, 23, 23, 0.1)',
                                    borderWidth: 2,
                                    outline: 'none',
                                    boxShadow: 'none',
                                    ...(error && {
                                        borderColor: 'rgba(221, 59, 7, 1)',
                                    }),
                                }),
                            }}
                            isMulti={isMulti}
                            controlShouldRenderValue={!isMulti}
                            isClearable={!isMulti}
                            classNames={{
                                control: ({ isDisabled }) =>
                                    cn(
                                        isDisabled
                                            ? 'bg-neutral-2100 text-neutral-1100 cursor-not-allowed border-none'
                                            : 'cursor-pointer',
                                        'text-neutral-250 text-sm font-medium',
                                    ),
                                option: ({ isFocused, isSelected }) =>
                                    cn(
                                        'text-neutral-250 text-sm font-medium hover:cursor-pointer',
                                        {
                                            'bg-primary-100 text-primary-900':
                                                isFocused || isSelected,
                                        },
                                    ),
                                valueContainer: () => 'text-sm',
                                noOptionsMessage: () => 'text-sm',
                                loadingMessage: () => 'text-sm',
                                menu: () => 'text-sm',
                                menuList: () => 'text-sm',
                                placeholder: () => 'text-sm font-medium text-neutral-50',
                            }}
                        />

                        <FormDescription>{description}</FormDescription>
                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};

export const MultiSelectValues = ({
    value,
    onRemove,
    disabledTooltip,
}: {
    value: { value: string; label: string; disabled?: boolean }[] | undefined;
    onRemove: (value: string) => void;
    disabledTooltip?: string;
}) => {
    if (!value?.length) return null;

    return (
        <div className="mb-2 box-border flex flex-wrap items-center gap-2">
            {value.map((option) => {
                if (option.disabled) {
                    return (
                        <DisabledValue
                            key={option.value}
                            value={option.label}
                            disabledTooltip={disabledTooltip}
                        />
                    );
                }

                return <RegularValue key={option.value} option={option} onRemove={onRemove} />;
            })}
        </div>
    );
};

const RegularValue = ({
    option,
    onRemove,
}: {
    option: { value: string; label: string; disabled?: boolean };
    onRemove: (value: string) => void;
}) => {
    return (
        <div key={option.value} className="flex rounded-md bg-[#E8E5F9] p-1.5 px-2 text-xs">
            <div className="font-semibold text-[#6550D3]">{option.label}</div>
            <button
                type="button"
                aria-label={`Remove ${option.label}`}
                onClick={() => onRemove(option.value)}
                className="ml-2"
            >
                <CancelCircleSymbol className="size-4 fill-[#6550D3]" />
            </button>
        </div>
    );
};

const DisabledValue = ({ value, disabledTooltip }: { value: string; disabledTooltip?: string }) => {
    return (
        <TooltipWhite content={disabledTooltip ?? ''}>
            <div className="flex items-center rounded-md bg-[#F1F1F1] p-1.5 px-2 text-xs hover:cursor-not-allowed">
                <div className="font-semibold text-[#1717174D]">{value}</div>

                <button type="button" className="ml-2" disabled>
                    <CancelCircleSymbol className="size-4 fill-[#1717174D]" />
                </button>
            </div>
        </TooltipWhite>
    );
};

const TooltipWhite = ({ children, content }: { children: React.ReactNode; content: string }) => {
    return (
        <TooltipProvider>
            <TooltipRoot>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <TooltipPrimitive.Portal>
                    <TooltipContent className="text-base-900 max-w-72 bg-white p-3">
                        {content}
                        <TooltipPrimitive.Arrow className="fill-white" />
                    </TooltipContent>
                </TooltipPrimitive.Portal>
            </TooltipRoot>
        </TooltipProvider>
    );
};

export default AsyncSelectField;
