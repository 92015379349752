import { FieldSchemaType, FormSchemaType } from './form-switch-staff-schema';

/**
 * Factory function to create an object with before and after keys with date values
 *
 * @param validation string[] | null - array of validation rules based on Laravel validation rules
 * @returns object - object with before and after keys with date values
 */
export const factoryDisabledDates = (validation: string[] | null) => {
    let disabledDates = {};
    if (validation) {
        const before = validation.find((v) => v.includes('before:'));
        const after = validation.find((v) => v.includes('after:'));
        const beforeOrEqual = validation.find((v) => v.includes('before_or_equal:'));
        const afterOrEqual = validation.find((v) => v.includes('after_or_equal:'));

        if (before) {
            const afterVal = new Date(before.split(':')[1] || '');

            disabledDates = {
                ...disabledDates,
                after: new Date(afterVal.setDate(afterVal.getDate() - 1)),
            };
        }

        if (beforeOrEqual) {
            disabledDates = {
                ...disabledDates,
                after: new Date(beforeOrEqual.split(':')[1] || ''),
            };
        }

        if (after) {
            const beforeVal = new Date(after.split(':')[1] || '');
            disabledDates = {
                ...disabledDates,
                before: new Date(beforeVal.setDate(beforeVal.getDate() + 1)),
            };
        }

        if (afterOrEqual) {
            disabledDates = {
                ...disabledDates,
                before: new Date(afterOrEqual.split(':')[1] || ''),
            };
        }
    }

    return disabledDates;
};

/**
 * Check if the field is required
 *
 * @param item field
 * @returns boolean
 */
export const checkRequired = (item: FieldSchemaType) => {
    let required = false;
    const requiredIf = item.validation?.find((v) => v.startsWith('required_if:'));

    if (item.validation?.includes('required')) {
        required = true;
    }

    if (requiredIf && requiredIf.length > 0) {
        required = true;
    }

    return required;
};

/**
 * Convert fields to settings to be posted to the API
 *
 * @param fields fields of a group
 * @param eventId
 * @returns object with metas and eventId
 */
export const fieldsToSettings = (
    fields: NonNullable<FormSchemaType['fields']>,
    eventId: string | null,
    userId: string,
): {
    metas: { key: string; value: unknown }[];
    eventId: number | null;
    userId: string;
} => {
    const metas = fields.map((field) => ({
        key: field.key,
        value: field.value,
    }));

    const eventIdInt = eventId ? Number(eventId) : null;

    return {
        userId,
        eventId: eventIdInt,
        metas,
    };
};
